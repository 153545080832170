import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import router from "./router/Router";
import { SnackbarProvider } from "notistack";
import { RecoilRoot } from "recoil";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Loader from "./component/Loader";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <RecoilRoot>
    <SnackbarProvider maxSnack={2}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RouterProvider router={createBrowserRouter(router)} />
      </LocalizationProvider>
    </SnackbarProvider>
    <Loader />
  </RecoilRoot>
);
