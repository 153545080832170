import { FerrisWheelSpinnerOverlay } from "react-spinner-overlay";
import { useRecoilState } from "recoil";
import { showLoaderAtom } from "../store/atom";

import styles from "../styles/Loader.module.css";

function Loader() {
  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  return (
    <>
      <div
        className={`${styles.spinnerOverlay} ${
          showLoader ? styles.fadeIn : styles.fadeOut
        }`}
      >
        <FerrisWheelSpinnerOverlay
          loading={showLoader}
          size={40}
          color={"#dd99ff"}
          zIndex={3000}
          style={{
            visibility: showLoader ? "visible" : "hidden",
          }}
        />
      </div>
    </>
  );
}

export default Loader;
