import axios from "axios";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Navigate } from "react-router-dom";
import { LANG, SYSTEM_CONST } from "../config/Const";
import { IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

//変数がセットされているか
export function isset(val) {
  return typeof val != "undefined";
}

//ユニーク文字列の生成
export function getUnique(myStrong) {
  var strong = 1000;
  if (myStrong) strong = myStrong;
  return (
    Math.floor(strong * Math.random()).toString(16) +
    new Date().getTime().toString(16)
  );
}

//ランダムな数値文字列の生成
export function numId(length) {
  return Math.random().toString(10).slice(-length);
}

//エラー要素へのスクロール
export function toErrScroll() {
  const errorElements = document.querySelectorAll(".__valid-err, .__valid_err");
  // console.dir(errorElements);

  for (let element of errorElements) {
    if (window.getComputedStyle(element).display !== "none") {
      // console.dir(element);

      // 前の要素を取得
      let previousElement = element.previousElementSibling;

      // 前の要素が存在する場合、その要素にスクロール
      if (previousElement) {
        previousElement.scrollIntoView({ behavior: "smooth" });
      } else {
        // 前の要素が存在しない場合、通常通り現在の要素にスクロール
        element.scrollIntoView({ behavior: "smooth" });
      }

      break;
    }
  }
}

//スナックバーのセット
export function setSnackBar(message, variant) {
  const action = (key) => (
    <IconButton
      aria-label="Close"
      color="inherit"
      onClick={() => closeSnackbar(key)}
    >
      <CloseIcon />
    </IconButton>
  );

  let duration = SYSTEM_CONST.SNACKBAR_SECOND_SUCCESS;
  if (variant !== "success") {
    duration = SYSTEM_CONST.SNACKBAR_SECOND_ERROR;
  }
  enqueueSnackbar(message, {
    variant: variant,
    autoHideDuration: duration,
    action,
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  });
}

//日付のフォーマット
export function formatDate(dateString, format) {
  let date = null;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }

  const padZero = (num) => num.toString().padStart(2, "0");

  const map = {
    YYYY: date.getFullYear(),
    MM: padZero(date.getMonth() + 1),
    DD: padZero(date.getDate()),
    HH: padZero(date.getHours()),
    mm: padZero(date.getMinutes()),
    ss: padZero(date.getSeconds()),
  };

  return format.replace(/YYYY|MM|DD|HH|mm|ss/g, (matched) => map[matched]);
}

// nl2brクラス配下の改行調整
export function nl2br() {
  let nl2br = document.getElementsByClassName("nl2br");
  Array.prototype.forEach.call(nl2br, function (item) {
    item.innerHTML = item.innerHTML.replace(/\n/g, "<br />");
  });
}

let apiRunnings = [];

// axios通信ラッパー
export function api({ type = "get", url = "", data = {}, addHeader = {} }) {
  return new Promise((resolve, reject) => {
    const runsKey = url + ":" + type;

    //多重送信防止
    if (apiRunnings.indexOf(runsKey) !== -1) {
      // console.dir("skip");
      return;
    }
    apiRunnings.push(runsKey);

    axios.defaults.headers.common = {
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    let topUrl = "/secure/auth";

    let axios_ret = null;

    let header = { withCredentials: true };

    if (addHeader) {
      header = { ...header, ...addHeader };
    }

    if (type === "post") {
      axios_ret = axios.post(url, data, header);
    } else if (type === "put") {
      axios_ret = axios.put(url, data, header);
    } else if (type === "delete") {
      axios_ret = axios.delete(url, header);
    } else {
      axios_ret = axios.get(url, header);
    }

    axios_ret

      .then((response) => {
        console.dir(response);

        const removals = [runsKey];
        apiRunnings = apiRunnings.filter(function (v) {
          return !removals.includes(v);
        });

        if (isset(response.data.csrfToken) && response.data.csrfToken) {
          document
            .querySelector('meta[name="csrf-token"]')
            .setAttribute("content", response.data.csrfToken);
        }

        resolve(response);
      })
      .catch((error) => {
        console.dir(error);
        // return;

        const removals = [runsKey];
        apiRunnings = apiRunnings.filter(function (v) {
          return !removals.includes(v);
        });

        //リダイレクト指示
        if (
          isset(error.response.data.redirect) &&
          error.response.data.redirect
        ) {
          if (window.location.href !== error.response.data.redirect) {
            window.location.href = error.response.data.redirect;
            return;
          }
        }

        // 400エラー
        if (isset(error.response.status)) {
          //認証エラー
          if (error.response.status === 401) {
            window.location.href = topUrl;
          } else if (error.response.status === 400) {
            type !== "get" && setSnackBar(error.response.data.message, "error");
          } else if (error.response.status === 403) {
            type !== "get" && setSnackBar(error.response.data.message, "error");
          } else if (error.response.status === 419) {
            type !== "get" && setSnackBar(LANG.ERR_SECURITY, "error");
          } else if (error.response.status === 500) {
            type !== "get" && setSnackBar(LANG.ERR_UNEXPECTED, "error");
          } else {
            type !== "get" && setSnackBar(error.message, "error");
          }

          reject(error);
        }
      });
  });
}

//CSVダウンロード（POST）
export function downloadCsvPost({ url = "", data = {}, fileName = "file"}) {
  return new Promise((resolve, reject) => {
    const runsKey = url + ":post";

    // 多重送信防止
    if (apiRunnings.includes(runsKey)) {
      return;
    }
    apiRunnings.push(runsKey);

    axios.defaults.headers.common = {
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    axios
      .post(url, data, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        const removals = [runsKey];
        apiRunnings = apiRunnings.filter(function (v) {
          return !removals.includes(v);
        });
      });
  });
}
