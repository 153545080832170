import { atom } from "recoil";

//ログイン情報
export const loginInfoAtom = atom({
  key: "loginInfoAtom",
  default: {},
});

//メニュー現在地
export const currentMenuAtom = atom({
  key: "currentMenuAtom",
  default: "creator",
});
//サブメニュー現在地
export const currentSubMenuAtom = atom({
  key: "currentSubMenuAtom",
  default: "",
});

//ローディング状態
export const showLoaderAtom = atom({
  key: "showLoaderAtom",
  default: false,
});

//ページモーション動作状態
export const pageMotionModeAtom = atom({
  key: "pageMotionModeAtom",
  default: "normal",
});

//ページモーション動作状態
export const alertsAtom = atom({
  key: "alertsAtom",
  default: [],
});
